import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
export class UsersApi {
    constructor(api) {
        this.api = api;
        this.apiController = 'users';
    }
    getAll() {
        return this.api.get(this.apiController).pipe(map((result) => {
            return result.users;
        }));
    }
    create(user) {
        return this.api.post(this.apiController, user)
            .pipe(map((response) => {
            return response.message;
        }));
    }
}
UsersApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersApi_Factory() { return new UsersApi(i0.ɵɵinject(i1.HttpService)); }, token: UsersApi, providedIn: "root" });
