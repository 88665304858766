import { UsersData } from '../../interfaces/users';
import { UsersApi } from '../api/users.api';
import * as i0 from "@angular/core";
import * as i1 from "../api/users.api";
export class UsersService extends UsersData {
    constructor(api) {
        super();
        this.api = api;
    }
    getAll() {
        return this.api.getAll();
    }
    create(userData) {
        return this.api.create(userData);
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.UsersApi)); }, token: UsersService, providedIn: "root" });
