import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Position, PositionsData} from '../../interfaces/positions';
import {PositionsApi} from '../api/positions.api';

@Injectable({
    providedIn: 'root',
  })
export class PositionsService extends PositionsData {

    constructor(private api: PositionsApi) {
        super();
    }

    getAll(): Observable<Position[]> {
        const result = this.api.getAll();
        return result;
    }
}
