import {Injectable} from '@angular/core';
import {Position} from '../interfaces/positions';


@Injectable()
export class ChartDataService {
    public calculatePositionsCountByTrader(data: Position[]) {
        return data.reduce((groups, item) => {
            groups[item.trader_id]
                ? groups[item.trader_id]++
                : groups[item.trader_id] = 1;
            return groups;
        }, {});
    }
}
