import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http.service";
export class PositionsApi {
    constructor(api) {
        this.api = api;
        this.apiController = 'positions';
    }
    getAll() {
        return this.api.get(this.apiController).pipe(map((result) => {
            return result.positions;
        }));
    }
}
PositionsApi.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PositionsApi_Factory() { return new PositionsApi(i0.ɵɵinject(i1.HttpService)); }, token: PositionsApi, providedIn: "root" });
