import { ExtraOptions, Routes } from '@angular/router';
import { NbLogoutComponent } from '@nebular/auth';
import { AuthGuard } from './@auth/auth.guard';
import { LoginComponent } from './@auth/components/login/login.component';
import { AuthComponent } from './@auth/components/auth/auth.component';
const ɵ0 = () => import("./pages/pages.module.ngfactory").then(m => m.PagesModuleNgFactory);
const routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: ɵ0,
    },
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'logout',
                component: NbLogoutComponent,
            },
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full',
            },
        ],
    },
    { path: '', redirectTo: 'pages', pathMatch: 'full' },
    { path: '**', redirectTo: 'pages' },
];
const config = {
    useHash: false,
    onSameUrlNavigation: 'reload',
};
export class AppRoutingModule {
}
export { ɵ0 };
