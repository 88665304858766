import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PositionsApi} from './api/positions.api';
import {HttpService} from './api/http.service';
import {PositionsData} from '../interfaces/positions';
import {PositionsService} from './services/positions.service';
import {NbAuthModule} from '@nebular/auth';
import {UsersData} from '../interfaces/users';
import {UsersService} from './services/users.service';
import {UsersApi} from './api/users.api';

const API = [
  PositionsApi,
  UsersApi,
  HttpService,
];

const SERVICES = [
    {provide: PositionsData, useClass: PositionsService},
    {provide: UsersData, useClass: UsersService},
];

@NgModule({
    imports: [CommonModule, NbAuthModule],
})
export class BackendModule {
    static forRoot(): ModuleWithProviders<BackendModule> {
        return {
            ngModule: BackendModule,
            providers: [
                ...API,
                ...SERVICES,
            ],
        };
    }
}
