import {Observable} from 'rxjs';

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  created: Date;
  last_login: Date;
}

export interface UserDTO {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export abstract class UsersData {

  abstract getAll(): Observable<User[]>;
  abstract create(userData: UserDTO): Observable<string>;
}
