import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {map} from 'rxjs/operators';
import {User, UserDTO} from '../../interfaces/users';

@Injectable({
  providedIn: 'root',
})
export class UsersApi {
  private readonly apiController: string = 'users';

  constructor(private api: HttpService) {
  }

  getAll(): Observable<User[]> {
    return this.api.get(this.apiController).pipe(
      map((result: { users: User[] }) => {
        return result.users;
      }),
    );
  }

  create(user: UserDTO): Observable<string> {
    return this.api.post(this.apiController, user)
      .pipe(
        map((response: {data: User, message: string}) => {
          return response.message;
        }),
      );
  }
}
