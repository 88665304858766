import { Observable } from 'rxjs';

export interface Position {
    id: string;
    market: string;
    trader_id: string;
    bought_at: Date;
    sold_at: Date;
    duration: number;
    type: string;
    open_price: number;
    close_price: number;
    profit: number;
    bought_qty: number;
    sold_qty: number;
    quote_price: number;
    take_profit: number;
    stop_limit: number;
    watch_price: number;
    last_close_price: number;
    status: string;
    stage: string;
    current_order_id: number;
}

export abstract class PositionsData {
  abstract getAll(): Observable<Position[]>;
}
