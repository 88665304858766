import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User, UserDTO, UsersData} from '../../interfaces/users';
import {UsersApi} from '../api/users.api';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends UsersData {

  constructor(private api: UsersApi) {
    super();
  }

  getAll(): Observable<User[]> {
    return this.api.getAll();
  }

  create(userData: UserDTO): Observable<string> {
    return this.api.create(userData);
  }
}
