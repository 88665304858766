import {ModuleWithProviders, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpRequest} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    NbAuthJWTInterceptor,
    NbAuthModule,
    NbAuthOAuth2JWTToken,
    NbPasswordAuthStrategy,
    NbTokenLocalStorage,
} from '@nebular/auth';
import {AuthInterceptor} from './auth.interceptor';
import {AuthGuard} from './auth.guard';
import {AuthPipe} from './auth.pipe';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {environment} from '../../environments/environment';
import {of as observableOf} from 'rxjs';
import {LoginComponent} from './components/login/login.component';
import {FormsModule} from '@angular/forms';
import {
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule,
    NbIconModule,
    NbInputModule,
    NbLayoutModule,
} from '@nebular/theme';
import {RouterModule} from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';

const GUARDS = [AuthGuard];
const PIPES = [AuthPipe];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
    return ['/authenticate']
        .some(url => req.url.includes(url));
}

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
        // here you could provide any role based on any auth flow
        return observableOf('guest');
    }
}

@NgModule({
    declarations: [
        ...PIPES,
        LoginComponent,
        AuthComponent,
    ],
    imports: [
        CommonModule,
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: environment.apiUrl,
                    login: {
                        endpoint: '/authenticate',
                        method: 'post',
                    },
                    logout: {
                        endpoint: '/logout',
                        method: 'post',
                    },
                    token: {
                        class: NbAuthOAuth2JWTToken,
                        key: 'token',
                    },
                }),
            ],
        }),
        FormsModule,
        NbAlertModule,
        NbInputModule,
        NbCheckboxModule,
        NbIconModule,
        RouterModule,
        NbButtonModule,
        NbLayoutModule,
    ],
    exports: [
        ...PIPES,
        LoginComponent,
    ],
    providers: [
        NbSecurityModule.forRoot().providers,
        {
            provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
        },
        {
            provide: NbTokenLocalStorage, useClass: NbTokenLocalStorage,
        },
    ],
})
export class AuthModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: AuthModule,
            providers: [
                {provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest},
                {provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true},
                {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
                ...GUARDS],
        };
    }
}
