import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule} from '@nebular/auth';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, ChartDataService} from './utils';
import {MockDataModule} from './mock/mock-data.module';
import {BackendModule} from './backend/backend.module';
import {UsersData} from './interfaces/users';
import {UsersService} from './backend/services/users.service';

const DATA_SERVICES = [
    {provide: UsersData, useClass: UsersService},
];

export const NB_CORE_PROVIDERS = [
    ...MockDataModule.forRoot().providers,
    ...BackendModule.forRoot().providers,
    ...DATA_SERVICES,
    AnalyticsService,
    ChartDataService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
