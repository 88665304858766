import { PositionsData } from '../../interfaces/positions';
import { PositionsApi } from '../api/positions.api';
import * as i0 from "@angular/core";
import * as i1 from "../api/positions.api";
export class PositionsService extends PositionsData {
    constructor(api) {
        super();
        this.api = api;
    }
    getAll() {
        const result = this.api.getAll();
        return result;
    }
}
PositionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PositionsService_Factory() { return new PositionsService(i0.ɵɵinject(i1.PositionsApi)); }, token: PositionsService, providedIn: "root" });
