import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Copyright &copy; 2021 <b><a href="https://cummings-capital.com" target="_blank">Cummings Capital Management</a></b> - All Rights Reserved</span>
    <div class="socials"></div>
  `,
})
export class FooterComponent {
}
