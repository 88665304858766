import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HttpService {
    constructor(http) {
        this.http = http;
    }
    get apiUrl() {
        return environment.apiUrl;
    }
    get(endpoint, options) {
        return this.http.get(`${this.apiUrl}/${endpoint}`, options);
    }
    post(endpoint, data, options) {
        return this.http.post(`${this.apiUrl}/${endpoint}`, data, options);
    }
    put(endpoint, data, options) {
        return this.http.put(`${this.apiUrl}/${endpoint}`, data, options);
    }
    delete(endpoint, options) {
        return this.http.delete(`${this.apiUrl}/${endpoint}`, options);
    }
}
HttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpService_Factory() { return new HttpService(i0.ɵɵinject(i1.HttpClient)); }, token: HttpService, providedIn: "root" });
