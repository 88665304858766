/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "../../../../../node_modules/@nebular/auth/index.ngfactory";
import * as i5 from "@nebular/auth";
import * as i6 from "@angular/router";
import * as i7 from "./auth.component";
import * as i8 from "@angular/common";
var styles_AuthComponent = [i0.styles];
var RenderType_AuthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "nb-layout", [], [[2, "window-mode", null], [2, "with-scroll", null], [2, "with-subheader", null]], [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NbLayoutComponent_0, i2.RenderType_NbLayoutComponent)), i1.ɵdid(1, 4374528, null, 0, i3.NbLayoutComponent, [i3.NbThemeService, i3.NbSpinnerService, i1.ElementRef, i1.Renderer2, i3.NB_WINDOW, i3.NB_DOCUMENT, i1.PLATFORM_ID, i3.NbLayoutDirectionService, i3.NbLayoutScrollService, i3.NbLayoutRulerService, i3.NbRestoreScrollTopHelper, i3.NbOverlayContainerAdapter], null, null), (_l()(), i1.ɵeld(2, 0, null, 3, 5, "nb-layout-column", [], [[2, "left", null], [2, "start", null]], null, null, i2.View_NbLayoutColumnComponent_0, i2.RenderType_NbLayoutColumnComponent)), i1.ɵdid(3, 49152, null, 0, i3.NbLayoutColumnComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "nb-auth-block", [], null, null, null, i4.View_NbAuthBlockComponent_0, i4.RenderType_NbAuthBlockComponent)), i1.ɵdid(5, 49152, null, 0, i5.NbAuthBlockComponent, [], null, null), (_l()(), i1.ɵeld(6, 16777216, null, 0, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).windowModeValue; var currVal_1 = i1.ɵnov(_v, 1).withScrollValue; var currVal_2 = i1.ɵnov(_v, 1).withSubheader; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 3).leftValue; var currVal_4 = i1.ɵnov(_v, 3).startValue; _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_AuthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i1.ɵdid(1, 180224, null, 0, i7.AuthComponent, [i5.NbAuthService, i8.Location], null, null)], null, null); }
var AuthComponentNgFactory = i1.ɵccf("ngx-auth", i7.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
