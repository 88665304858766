import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Position} from '../../interfaces/positions';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
  })
export class PositionsApi {
    private readonly apiController: string = 'positions';

    constructor(private api: HttpService) {
    }

    getAll(): Observable<Position[]> {
        return this.api.get(this.apiController).pipe(
            map((result: { positions: Position[] }) => {
                return result.positions;
            }),
        );
    }
}
